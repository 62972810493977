$primary: #FD9801;
$dark: #475153;
$light: #F0F3F5;
$headings-font-weight: 300;
$lead-font-weight: 400;

.btn-primary {
  color: #FFFFFF !important;
}

@import "node_modules/bootstrap/scss/bootstrap";
@import url('https://fonts.googleapis.com/css?family=Roboto:300,400&display=swap&subset=cyrillic');
@import url('https://cdn.jsdelivr.net/npm/cookieconsent@3/build/cookieconsent.min.css');



$fa-font-path:  "../fonts";
@import "node_modules/@fortawesome/fontawesome-free/scss/solid";
//@import "node_modules/@fortawesome/fontawesome-free/scss/regular";
@import "node_modules/@fortawesome/fontawesome-free/scss/fontawesome";
@import "slicknav";
@import "slick";
@import "themify-icons";
//header {
//  position: fixed; z-index: 10; width: 100%;
//  -webkit-transform: translateZ(0);
//}

header.large img{
  height: 80px;
}
header.large div.phone{
  margin-top: 1.5em;
  font-size: 1rem;
  transition: all 1s;
  -moz-transition: all 1s; /* Firefox 4 */
  -webkit-transition: all 1s; /* Safari and Chrome */
  -o-transition: all 1s; /* Opera */

}
header.small div.phone{
  margin-top: 1em;
}
@media (min-width: 992px) {
  header.small div.phone{

  }
}
header.small div.logo{
  margin-top: 7px;
  margin-bottom: 7px;
}
header.large div.logo{
  margin-bottom: 1.5rem;
}
@media (min-width: 992px) {
  header.small div.logo{
    margin-top: 0;
  }
}
header.small img{
  height: 40px;
}
header.small {
  font-size: 80%;
}
header.small .nav-link,  header.small .navbar {
  padding: .1rem 0 .3rem;
}
header.large ~ #carouselExampleCaptions {
  top: 126px;
}
header.small ~ #carouselExampleCaptions {
  top: 68px;
}
header img{
  transition: all 1s;
  -moz-transition: all 1s; /* Firefox 4 */
  -webkit-transition: all 1s; /* Safari and Chrome */
  -o-transition: all 1s; /* Opera */
}
.navbar-toggler {
  padding: 3px;
}
.navbar-collapse ul li {
  text-align: right;
}
.navbar-toggler {
  margin-top: 7px;
}
.navbar-primary .navbar-toggler {
  color: rgba(0,0,0,.5);
  border-color: rgba(0,0,0,.1);
}
.navbar-primary {
  .navbar-toggler-icon {
    background-image: escape-svg($navbar-light-toggler-icon-bg);
  }
}


body {
  font-family: 'Roboto', sans-serif;
  //font-weight: normal;
  font-style: normal;
  font-weight: 300;
}
header ul {
  margin: 0px;
  padding: 0px;
}
.header-area {
  left: 0;
  right: 0;
  width: 100%;
  top: 0;
  z-index: 9;
  position: absolute;
}
@media (max-width: 767px) {
  .header-area {
    padding-top: 0;
  }
}
@media (min-width: 768px) and (max-width: 991px) {
  .header-area {
    padding-top: 0;
  }
}
.header-area .main-header-area {
  padding: 18px 0;
  background: transparent;
}
.header-area .main-header-area  img {
  width: 100px;
}
.header-area .main-header-area.sticky img {
  width: 60px;
}

.header-area .main-header-area .Appointment {
  display: -webkit-box;
  display: -moz-box;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  -webkit-align-items: center;
  -moz-align-items: center;
  -ms-align-items: center;
  align-items: center;
  -webkit-justify-content: flex-end;
  -moz-justify-content: flex-end;
  -ms-justify-content: flex-end;
  justify-content: flex-end;
  -ms-flex-pack: flex-end;
}

@media (min-width: 992px) and (max-width: 1200px) {
  .header-area .main-header-area .Appointment .search_button {
    margin-right: 15px;
  }
}

@media (min-width: 1200px) and (max-width: 1500px) {
  .header-area .main-header-area .Appointment .search_button {
    margin-right: 15px;
  }
}
.header-area .main-header-area .Appointment .search_button a i {
  color: #E8E8E8;
}

.header-area .main-header-area .Appointment .book_btn {
  margin-left: 30px;
}

@media (min-width: 992px) and (max-width: 1200px) {
  .header-area .main-header-area .Appointment .book_btn {
    margin-left: 0;
  }
}

@media (min-width: 1200px) and (max-width: 1500px) {
  .header-area .main-header-area .Appointment .book_btn {
    margin-left: 0;
  }
}

.header-area .main-header-area .Appointment .book_btn a {
  background: #FD9801;
  padding: 12px 26px;
  font-size: 14px;
  font-weight: 400;
  border: 1px solid transparent;
  color: #fff;
  -webkit-border-radius: 4px;
  -moz-border-radius: 4px;
  border-radius: 4px;
}

@media (min-width: 992px) and (max-width: 1200px) {
  .header-area .main-header-area .Appointment .book_btn a {
    padding: 12px 20px;
  }
}

.header-area .main-header-area .Appointment .book_btn a:hover {
  background: #FD9801;
  color: #fff;
  border: 1px solid #FD9801;
}

.header-area .main-header-area .main-menu {
  text-align: center;
  padding: 12px 0;
}

.header-area .main-header-area .main-menu ul li {
  display: inline-block;
  position: relative;
  margin: 0 10px;
}

.header-area .main-header-area .main-menu ul li a {
  color: #fff;
  font-size: 15px;
  text-transform: capitalize;
  font-weight: 400;
  display: inline-block;
  padding: 0;
  position: relative;
  text-transform: capitalize;
}

@media (min-width: 992px) and (max-width: 1200px) {
  .header-area .main-header-area .main-menu ul li a {
    font-size: 15px;
  }
}

@media (min-width: 1200px) and (max-width: 1500px) {
  .header-area .main-header-area .main-menu ul li a {
    font-size: 15px;
  }
}

.header-area .main-header-area .main-menu ul li a i {
  font-size: 9px;
}

@media (max-width: 767px) {
  .header-area .main-header-area .main-menu ul li a i {
    display: none !important;
  }
}

@media (min-width: 768px) and (max-width: 991px) {
  .header-area .main-header-area .main-menu ul li a i {
    display: none !important;
  }
}

.header-area .main-header-area .main-menu ul li a:hover::before {
  opacity: 1;
  transform: scaleX(1);
}

.header-area .main-header-area .main-menu ul li a.active::before {
  opacity: 1;
  transform: scaleX(1);
}

.header-area .main-header-area .main-menu ul li a:hover {
  color: #FD9801;
}

.header-area .main-header-area .main-menu ul li .submenu {
  position: absolute;
  left: 0;
  top: 160%;
  background: #fff;
  width: 200px;
  z-index: 2;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.02);
  opacity: 0;
  visibility: hidden;
  text-align: left;
  -webkit-transition: 0.6s;
  -moz-transition: 0.6s;
  -o-transition: 0.6s;
  transition: 0.6s;
}

.header-area .main-header-area .main-menu ul li .submenu li {
  display: block;
}

.header-area .main-header-area .main-menu ul li .submenu li a {
  padding: 10px 15px;
  position: inherit;
  -webkit-transition: 0.3s;
  -moz-transition: 0.3s;
  -o-transition: 0.3s;
  transition: 0.3s;
  display: block;
  color: #000;
}
.header-area .main-header-area .main-menu ul li .submenu li a::before {
  display: none;
}
.header-area .main-header-area .main-menu ul li .submenu li:hover a {
  color: #000;
}
.header-area .main-header-area .main-menu ul li:hover > .submenu {
  opacity: 1;
  visibility: visible;
  top: 150%;
}
.header-area .main-header-area .main-menu ul li:hover > a::before {
  opacity: 1;
  transform: scaleX(1);
}
.header-area .main-header-area .main-menu ul li:first-child a {
  padding-left: 0;
}
.header-area .main-header-area.sticky {
  box-shadow: 0px 3px 16px 0px rgba(0, 0, 0, 0.1);
  position: fixed;
  width: 100%;
  top: -70px;
  left: 0;
  right: 0;
  z-index: 990;
  transform: translateY(70px);
  transition: transform 500ms ease, background 500ms ease;
  -webkit-transition: transform 500ms ease, background 500ms ease;
  box-shadow: 0px 3px 16px 0px rgba(0, 0, 0, 0.1);
  background: rgba(255, 255, 255, 0.96);
  background: #2C2C2C;
  padding-bottom: 0;
}

@media (max-width: 767px) {
  .header-area .main-header-area.sticky {
    padding: 10px 10px;
  }
}

.header-area .main-header-area.sticky .main-menu {
  padding: 0;
}

.main-page .header-area .main-header-area.sticky .header_bottom_border-page {
  border-bottom: none;
}

.header-area .header-top_area {
  padding: 12px 0;
  background: rgba(71, 81, 83, 0.5);
}

@media (max-width: 767px) {
  .header-area .header-top_area .social_media_links {
    text-align: center;
  }
}
.header-area .header-top_area .social_media_links a {
  font-size: 15px;
  color: #fff;
  margin-right: 12px;
}
.header-area .header-top_area .social_media_links a:hover {
  color: #FD9801;
}

.header-area .header-top_area .short_contact_list {
  text-align: right;
}

@media (max-width: 767px) {
  .header-area .header-top_area .short_contact_list {
    text-align: center;
  }
}

.header-area .header-top_area .short_contact_list ul li {
  display: inline-block;
}

.header-area .header-top_area .short_contact_list ul li a {
  font-size: 13px;
  color: #fff;
  margin-left: 50px;
}

@media (max-width: 767px) {
  .header-area .header-top_area .short_contact_list ul li a {
    margin-left: 0;
    margin: 0 5px;
  }
}

.header-area .header-top_area .short_contact_list ul li a i,
.header-area .header-top_area .short_contact_list ul li a svg{
  color: #FD9801;
  margin-right: 7px;
}
@media (max-width: 767px) {
  .mobile_menu {
    position: absolute;
    right: 0px;
    width: 100%;
    z-index: 9;
  }
}

.slicknav_menu .slicknav_nav {
  background: #fff;
  float: right;
  width: 95%;
  padding: 0;
  border-radius: 0px;
  margin-top: 5px;
  position: absolute;
  left: 0;
  right: 0;
  margin: auto;
  top: 11px;
}

.slicknav_menu .slicknav_nav a:hover {
  background: transparent;
  color: #FD9801;
}

.slicknav_menu .slicknav_nav a.active {
  color: #FD9801;
}

@media (max-width: 767px) {
  .slicknav_menu .slicknav_nav a i {
    display: none;
  }
}

@media (min-width: 768px) and (max-width: 991px) {
  .slicknav_menu .slicknav_nav a i {
    display: none;
  }
}

.slicknav_menu .slicknav_nav .slicknav_btn {
  background-color: transparent;
  cursor: pointer;
  margin-bottom: 10px;
  margin-top: -40px;
  position: relative;
  z-index: 99;
  border: 1px solid #ddd;
  right: 5px;
  top: -36px;
}

.slicknav_menu .slicknav_nav .slicknav_btn .slicknav_icon {
  margin-right: 6px;
  margin-top: 3px;
  position: relative;
  padding-bottom: 3px;
  top: -11px;
  right: -5px;
}

@media (max-width: 767px) {
  .slicknav_menu {
    margin-right: 0;
  }
}

.slicknav_nav .slicknav_arrow {
  float: right;
  font-size: 22px;
  position: relative;
  top: -9px;
}

.slicknav_btn {
  background-color: transparent;
  cursor: pointer;
  margin-bottom: 10px;
  position: relative;
  z-index: 99;
  border: none;
  border-radius: 3px;
  padding: 5px;
  right: 0;
  margin-top: -5px;
  top: -33px;
}
.main-page .header_bottom_border-page {
  border-bottom: 1px solid #7F8284;
}
.header_bottom_border-page {
  padding-bottom: 22px;
}


@media (max-width: 767px) {
  .header_bottom_border-page {
    padding: 0;
    border-bottom: none;
  }
}

.slider_bg_1 {
  background-image: url(../img/banner/banner.png);
}

//.slider_bg_2 {
//  background-image: url(../img/IMG-20190628-WA0011a.jpg);
//}
//
//
.slider_area .single_slider {
  height: 900px;
  //background-size: cover;
  //background-repeat: no-repeat;
  //background-position: center center;
}
.slider_area .single_slider.overlay{
  height: 160px;
}

@media (max-width: 767px) {
  .slider_area .single_slider {
    height: 100vh;
  }
}

.slider_area .single_slider .slider_text h3 {
  color: #fff;
  //font-family: Poppins,sans-serif;
  font-size: 44px;
  /* text-transform: capitalize; */
  /* letter-spacing: 2px; */
  /* line-height: 62px; */
  margin-bottom: 60px;
}

.slider_area .single_slider .slider_text h3 span {
  font-weight: 700;
}

@media (max-width: 767px) {
  .slider_area .single_slider .slider_text h3 {
    margin-top: 45px;
  }
}

@media (max-width: 767px) {
  .slider_area .single_slider .slider_text h3 {
    font-size: 25px;
    line-height: 30px;
  }
}

@media (min-width: 768px) and (max-width: 991px) {
  .slider_area .single_slider .slider_text h3 {
    font-size: 30px;
  }
}

@media (min-width: 992px) and (max-width: 1200px) {
  .slider_area .single_slider .slider_text h3 {
    font-size: 35px;
    letter-spacing: 3px;
  }
}

.slider_area .single_slider .slider_text p {
  font-size: 20px;
  font-weight: 400;
  color: #D5D5D5;
  margin-bottom: 24px;
  margin-top: 10px;
}

@media (min-width: 992px) and (max-width: 1200px) {
  .slider_area .single_slider .slider_text p {
    font-size: 16px;
  }
}

@media (max-width: 767px) {
  .slider_area .single_slider .slider_text p {
    font-size: 16px;
  }
}

.slider_area .single_slider .slider_text .video_service_btn > a {
  margin-right: 15px;
}

@media (max-width: 767px) {
  .slider_area .single_slider .slider_text .video_service_btn > a {
    margin-bottom: 20px;
  }
}
.boxed-btn3 {
  background: #FD9801;
  display: inline-block;
  padding: 14px 27px;
  font-weight: 400;
  border: 0;
  -webkit-border-radius: 5px;
  -moz-border-radius: 5px;
  border-radius: 5px;
  text-align: center;
  color: #fff !important;
  -webkit-transition: 0.5s;
  -moz-transition: 0.5s;
  -o-transition: 0.5s;
  transition: 0.5s;
  cursor: pointer;
}
.boxed-btn3:hover {
  background: #E98C01;
  color: #fff !important;
}
.boxed-btn3:focus {
  outline: none;
}
.boxed-btn3.large-width {
  width: 220px;
}
.boxed-btn3-white {
  display: inline-block;
  padding: 13px 27px;
  font-weight: 400;
  border: 1px solid #fff;
  -webkit-border-radius: 5px;
  -moz-border-radius: 5px;
  border-radius: 5px;
  text-align: center;
  color: #fff !important;
  -webkit-transition: 0.5s;
  -moz-transition: 0.5s;
  -o-transition: 0.5s;
  transition: 0.5s;
  cursor: pointer;

}
.boxed-btn3-white:hover {
  background: #E98C01;
  color: #fff !important;
  border: 1px solid transparent;
}
.boxed-btn3-white i {
  margin-right: 2px;
}
.boxed-btn3-white:focus {
  outline: none;
}
.boxed-btn3-white.large-width {
  width: 220px;
}
.overlay {

}
.overlay2, .overlay {
  position: relative;
  z-index: 0;
  overflow:  hidden;
}
.overlay2::before, .overlay::before {
  position: absolute;
  content: "";
  //background-color: #2C2C2C;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: -1;
  //opacity: 0.6;
}
.overlay::before {
  background-image: url(../img/polosa2.jpg);
  height: 160px;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: 50% 34%;
}

.overlay2::before {
  background-image: url(../img/track-cover.jpg);
  height: 900px;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center center;
  transition: transform 5s;
  overflow:  hidden;
}

.overlay2:hover::before  {
  transform: scale(1.1,1.1);

}






section {
  padding: 60px 0;
}

section .section-title {
  text-align: center;
  color: #007b5e;
  margin-bottom: 50px;
  text-transform: uppercase;
}

/* Normal desktop :1200px. */
$large_device:'(min-width: 1200px) and (max-width: 1500px)';

/* Normal desktop :992px. */
$mid_device:'(min-width: 992px) and (max-width: 1200px)';

/* Tablet desktop :768px. */
$tablet_device:'(min-width: 768px) and (max-width: 991px)';

/* small mobile :320px. */
$mobile_device:'(max-width: 767px)';

/* Large Mobile :480px. */
$large_mobile:'only screen and (min-width: 480px) and (max-width: 767px)';

.footer{
margin-top: 12em;
  .footer_top_page{
    background: #475153;
    background-image: url(../img/polosa5.jpg);
    background-position: top;
    background-repeat: no-repeat;
    padding-top: 380px;
    padding-bottom: 129px;

    @media #{$mobile_device} {
      //padding-top: 60px;
      padding-bottom: 30px;
    }
    .footer_widget{
      @media #{$mobile_device} {
        margin-bottom: 30px;
      }
      @media #{$tablet_device} {
        margin-bottom: 30px;
      }
      .footer_title{
        font-size: 22px;
        font-weight: 400;
        color: #fff;
        text-transform: capitalize;
        margin-bottom: 40px;
        @media #{$mobile_device} {
          margin-bottom: 20px;
        }
      }
      .footer_logo{
        font-size: 22px;
        font-weight: 400;
        color: #fff;
        text-transform: capitalize;
        margin-bottom: 40px;
        @media #{$mobile_device} {
          margin-bottom: 20px;
        }
        img{
          width: 100px;
        }
      }
      p{
        color: #C7C7C7;
      }
      p.footer_text{
        font-size: 16px;
        color: #B2B2B2;
        margin-bottom: 23px;
        font-weight: 400;
        line-height: 28px;
        a.domain{
          color: #B2B2B2;
          font-weight: 400;
          &:hover{
            color: #28AE60;
            border-bottom: 1px solid #28AE60
          }
        }
        &.doanar{
          a{
            font-weight: 500;
            color: #B2B2B2;

            &:hover{
              color: #28AE60;
              border-bottom: 1px solid #28AE60
            }
            &.first{
              margin-bottom: 10px;
            }
          }

        }
      }
      ul{
        margin: 0;
        padding: 0;
        li{
          color: #C7C7C7;
          font-size: 13px;
          line-height: 42px;
          list-style: none;
          a{
            color: #C7C7C7;
            &:hover{
              color: #FD9801;
            }
          }
        }
      }
      .newsletter_form{
        position: relative;
        margin-bottom: 20px;
        input{
          width: 100%;
          height: 45px;
          background: #fff;
          padding-left: 20px;
          font-size: 16px;
          color: #000;
          border: none;
          &::placeholder{
            font-size: 16px;
            color: #919191;
          }
        }
        button{
          position: absolute;
          top: 0;
          right: 0;
          height: 100%;
          border: none;
          font-size: 14px;
          color: #fff;
          background: #28AE60;
          padding: 10px;
          padding: 0 22px;
          cursor: pointer;
        }
      }
      .newsletter_text{
        font-size: 16px;
        color: #BABABA;
      }
    }
  }
  .copy-right_text{
    padding-bottom: 30px;
    background: #1F1F1F;
    .footer_border{
      padding-bottom: 30px;
    }
    .copy_right{
      font-size: 13px;
      color:#C7C7C7;
      margin-bottom: 0;
      font-weight: 400;
      @media #{$mobile_device} {
        font-size: 13px;
      }
      a{
        color: #fd9801;
      }
    }
  }
  .socail_links{
    margin-top: 47px;
    @media #{$mobile_device} {
      margin-top: 30px;
    }
    ul{
      li{
        display: inline-block;

        a{
          font-size: 18px;
          color: #FD9801;
          width: 40px;
          height: 40px;
          display: inline-block;
          text-align: center;
          background: #2C2C2C;
          @include border-radius(50%);
          line-height: 40px !important;
          margin-right: 7px;
          line-height: 41px !important;
          &:hover{
            color: #fff !important;
            background: #FD9801;
            text-decoration: none;
          }
        }
      }
    }
  }
}











.review  {
  font-size: 18px;
}

.slick-dots {
  position: relative;
}
.next-slick, .prev-slick {
  font-size: 32px;
  line-height: 1.2;
  position: absolute;
  top: 50%;
  display: block;
  width: 40px;
  height: 40px;
  padding: 0;
  transform: translate(0,-50%);
  cursor: pointer;
  color: #fff;
  border: none;
  outline: 0;
  background: #FED699;
  border-radius: 50%;
  text-align: center;
}
.next-slick:hover, .prev-slick:hover {
  background: #FD9801;
}
.next-slick {
  right: -40px;
}
.prev-slick {
  left: -40px;
}
.portfolio svg {
  color: #FD9801;
}
.portfolio div+p, .portfolio {
  font-weight: 400;
}
.promotext {
  margin-bottom: 3em;
}
.card h5 span {
  color: #FD9801;
}
.card.bg-primary h5 span {
  color: #000;
}
.polosa2{
  background-image: url(../img/polosa2.jpg);
  background-position: center;
  background-repeat: no-repeat;
  padding-bottom:300px
}





.masonry { /* Masonry container */
  -webkit-column-count: 4;
  -moz-column-count:4;
  column-count: 4;
  -webkit-column-gap: 1em;
  -moz-column-gap: 1em;
  column-gap: 1em;
  margin: 0;
  padding: 0;
  -moz-column-gap: 1.5em;
  -webkit-column-gap: 1.5em;
  column-gap: 1.5em;
  font-size: 0;
}
.item {
  display: inline-block;
  background: #fff;
  padding: 0;
  margin: 0;
  width: 100%;
  -webkit-transition:1s ease all;
  //box-sizing: border-box;
  //-moz-box-sizing: border-box;
  //-webkit-box-sizing: border-box;
  //box-shadow: 2px 2px 4px 0 #ccc;
}
.item img{max-width:100%; height: auto;}

//.item:hover img {
//  transform: scale(1.1,1.1);
//  transition: transform 3s;
//}

@media only screen and (max-width: 320px) {
  .masonry {
    -moz-column-count: 1;
    -webkit-column-count: 1;
    column-count: 1;
  }
}

@media only screen and (min-width: 321px) and (max-width: 768px){
  .masonry {
    -moz-column-count: 2;
    -webkit-column-count: 2;
    column-count: 2;
  }
}
@media only screen and (min-width: 769px) and (max-width: 1200px){
  .masonry {
    -moz-column-count: 3;
    -webkit-column-count: 3;
    column-count: 3;
  }
}
@media only screen and (min-width: 1201px) {
  .masonry {
    -moz-column-count: 4;
    -webkit-column-count: 4;
    column-count: 4;
  }
}
.background-gray {
  background: #F0F0F0;
}
.review img {
  height: 400px;
}
footer {
  font-weight: 400;
}
.background-track {
  background-image: url(../img/bg2.jpg);
  background-position: center;
  background-repeat: no-repeat;
  background-attachment: fixed;
  background-size: cover;
}
.background-track, .background-track svg {
  color:#fff;
}
.background-track p {
  font-size: 1.35em;
}
.formwrapper {
  position: relative;
  padding: 35px;
  z-index: 2;
}
.form-slide, #ajax-loader, .error-message-modal, .success-message-modal{
  display: none;
}
.bg-form {
  background-color: #fff;
  opacity: 0.7;
  position: absolute;
  top: 0;
  right: 0;
  left: 0;
  bottom: 0;
}
#portfolio, .promotext, .background-track, .card-slide1, .card-slide2, .card-slide3, .masonry, .comment, .main-page .footer_top_page .container, .card-div {
  visibility: hidden;
}



@-webkit-keyframes effect1 {
  0% {
    opacity: 0;
    -webkit-transform: scale(.1);
    transform: scale(.1);
  }

  100% {
    opacity: 1;
    -webkit-transform: scale(1);
    transform: scale(1);
  }
}

@keyframes effect1 {
  0% {
    opacity: 0;
    -webkit-transform: scale(.1);
    -ms-transform: scale(.1);
    transform: scale(.1);
  }



  100% {
    opacity: 1;
    -webkit-transform: scale(1);
    -ms-transform: scale(1);
    transform: scale(1);
  }
}

.effect1 {
  -webkit-animation: 1s 0s forwards effect1;
  animation: 1s 0s forwards effect1;
}

@keyframes effect2 {
  0% {
    opacity: 0;
    -webkit-transform: translateY(70px);
    -ms-transform: translateY(70px);
    transform: translateY(70px);
  }

  100% {
    opacity: 1;
    -webkit-transform: translateY(0);
    -ms-transform: translateY(0);
    transform: translateY(0);
  }
}

@-webkit-keyframes effect2 {
  0% {
    opacity: 0;
    -webkit-transform: translateY(70px);
    -ms-transform: translateY(70px);
    transform: translateY(70px);
  }

  100% {
    opacity: 1;
    -webkit-transform: translateY(0);
    -ms-transform: translateY(0);
    transform: translateY(0);
  }
}

.effect2 {
  -webkit-animation: 1s 0s forwards effect2;
  animation: 1s 0s forwards effect2;
}


@keyframes effect3 {
  0% {
    opacity: 0;
    -webkit-transform: translateX(-250px);
    -ms-transform: translateX(-250px);
    transform: translateX(-250px);
  }

  100% {
    opacity: 1;
    -webkit-transform: translateX(0);
    -ms-transform: translateX(0);
    transform: translateX(0);
  }
}

@-webkit-keyframes effect3 {
  0% {
    opacity: 0;
    -webkit-transform: translateX(-250px);
    -ms-transform: translateX(-250px);
    transform: translateX(-250px);
  }

  100% {
    opacity: 1;
    -webkit-transform: translateX(0);
    -ms-transform: translateX(0);
    transform: translateX(0);
  }
}

.effect3 {
  -webkit-animation: 1.0s 0s forwards effect3;
  animation: 1.0s 0s forwards effect3;
}


@keyframes effect4 {
  0% {
    opacity: 0;
    -webkit-transform: translateX(250px);
    -ms-transform: translateX(250px);
    transform: translateX(250px);
  }

  100% {
    opacity: 1;
    -webkit-transform: translateX(0);
    -ms-transform: translateX(0);
    transform: translateX(0);
  }
}

@-webkit-keyframes effect4 {
  0% {
    opacity: 0;
    -webkit-transform: translateX(250px);
    -ms-transform: translateX(250px);
    transform: translateX(250px);
  }

  100% {
    opacity: 1;
    -webkit-transform: translateX(0);
    -ms-transform: translateX(0);
    transform: translateX(0);
  }
}

.effect4 {
  -webkit-animation: 1.3s 0s forwards effect4;
  animation: 1.3s 0s forwards effect4;
}



@keyframes effect5{
  0% {
    opacity: 0;
    -webkit-transform: translateY(-50px);
    -ms-transform: translateY(-50px);
    transform: translateY(-50px);
  }

  100% {
    opacity: 1;
    -webkit-transform: translateY(0);
    -ms-transform: translateY(0);
    transform: translateY(0);
  }
}

@-webkit-keyframes effect5 {
  0% {
    opacity: 0;
    -webkit-transform: translateY(-50px);
    -ms-transform: translateY(-50px);
    transform: translateY(-50px);
  }

  100% {
    opacity: 1;
    -webkit-transform: translateY(0);
    -ms-transform: translateY(0);
    transform: translateY(0);
  }
}

.effect5 {
  -webkit-animation: 1s 0s forwards effect5;
  animation: 1s 0s forwards effect5;
}

@keyframes effect6 {
  0% {
    opacity: 0;
    -webkit-transform: translate(-50px, -50px);
    -ms-transform: translate(-50px, -50px);
    transform: translate(-50px, -50px);
  }

  100% {
    opacity: 1;
    -webkit-transform: translate(0, 0);
    -ms-transform: translate(0, 0);
    transform: translate(0, 0);
  }
}

@-webkit-keyframes effect6 {
  0% {
    opacity: 0;
    -webkit-transform: translate(-50px, -50px);
    -ms-transform: translate(-50px, -50px);
    transform: translate(-50px, -50px);
  }

  100% {
    opacity: 1;
    -webkit-transform: translate(0, 0);
    -ms-transform: translate(0, 0);
    transform: translate(0, 0);
  }
}

.effect6 {
  -webkit-animation: 0.8s 0s forwards effect6;
  animation: 0.8s 0s forwards effect6;
}
.slider_text {
  display: none;
}
.promotext ul li {
  list-style: none;

}
.promotext ul li::before {
  font-family: "Font Awesome 5 Free";
  position: absolute;
  left: 20px;
  content: '\f058';
  color: #FD9801;

}
.blockimg1, .blockimg2, .blockimg-menu {
  width: auto;
  height: 100%;
  background: #ccc;
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  display: block;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  margin: 0 15px;
}
.blockimg-menu {
  margin: 0;
  background-position: center center;
}

.card.left-menu {
  border: 0;
}
.blog_item_date {
  bottom: -20px;
  left: 40px;
  padding: 13px 30px;
}
.blog_item_date {
  position: absolute;
  display: block;
  color: #fff;
  background-color: #FD9801;
  border-radius: 5px;
}
.blog_item_date p {
  margin: 0;
  text-align: center;
}
.row-blog .card {
    box-shadow: 0px 10px 20px 0px rgba(221, 221, 221, 0.3);
}
.row-blog h5.card-title {
  font-weight: bold;
}
.contact-form-content {
  position: relative;
}
.bg-form.form-contact {
  background-color: #FD9801;
  background-image: url(../img/bg2.jpg);
  //background-size: cover;
  background-position: center bottom ;
  opacity: 1;
}
.concealed {
  display: none;
}
.contact-form-content .form-control {
  border: none;
}
.contact-details-content address {
  border-left: 2px solid #FD9801;
  padding-left: .625rem;
  margin-bottom: 1.25rem;
}
.left-menu a {
  color: inherit;
  text-decoration: none;
}
.left-menu.bg-light:hover {
  background-color: $primary !important;
  @include transition($btn-transition);
}
.left-menu.bg-light:hover .card-body{
  color: #fff;
}
.left-menu.bg-light:hover h5 span {
  color: #000;
}
p.anons::after {
  background: #fff none repeat scroll 0 0;
  box-shadow: -15px 5px 15px 15px #fff;
  content: "";
  display: inline-block;
  height: 10px;
  width: 10px;
}
.blog-list li a {
  color: #000;
  font-size: 18px;
}
.blog-list h5 {
  color: #fff;
}
.blog-list .card-body{
  background: rgba(71, 81, 83, .3);
  //background-image: url(../img/polosa2.jpg);
  //background-size: cover;
}
.blog-list .list-group-flush .list-group-item {
  border: none;
  background: transparent;
}
.card.blog-list  {
  //background: #fd9801;
  //background-image: url(../img/bg2.jpg);
  background-image: url(../img/team-bg3.jpg);
  background-size: cover;
  border: none;
}

.blog-list .list-group{
  background: rgba(255, 255, 255, .4);
}
.tab-content {
  padding: 2rem 0 0;
}

.admin-link-table.del{
  position: absolute;
  top: 10px;
  bottom: 10px!important;
  right: 30px;
  display: none;
}
.admin-link-table.del a{
 color: red;
}
.admin-img:hover .admin-link-table {
  display: block;
}
.custom-file-label::after {
  content: "Обзор";
}
#form-comment {
  display: none;
}

